import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { Team, Member, TeamProps, TeamValues, User } from "../types";

export const TeamContext = createContext({} as TeamValues);

const TeamContextProvider = (props: TeamProps): ReactElement<TeamValues> => {
  const [userTeams, setUserTeams] = useState<Team[]>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [teamMembers, setTeamMembers] = useState<Member[]>();
  const [totalSharedChatbotCount, setTotalSharedChatbotCount] =
    useState<number>(0);
  const [userRoleInTeam, setUserRoleInTeam] = useState<string>('');

  const findUserRole = (user: User) => {
    // console.log("selectedTeam", selectedTeam);
    if (selectedTeam) {
      let currentTeamMember: any =
        selectedTeam &&
        selectedTeam.members &&
        selectedTeam?.members.find((m: any) => m.email === user?.email);
      setUserRoleInTeam(currentTeamMember.role)
      console.log("userRoleInTeam: ", userRoleInTeam)
      console.log("currentTeamMember.role: ", currentTeamMember.role)
    }
  }


  const addUserTeams = (teams: Team[]) => {
    setUserTeams(teams);
  };

  const updateUserTeam = (team: Team, teamId: string) => {
    setUserTeams(
      userTeams?.map((t: Team) => {
        if (t.id === teamId) {
          setSelectedTeam(team);
          return { ...t, team };
        } else return t;
      })
    );
  };

  return (
    <TeamContext.Provider
      value={{
        userTeams,
        setUserTeams,
        selectedTeam,
        setSelectedTeam,
        addUserTeams,
        teamMembers,
        setTeamMembers,
        updateUserTeam,
        totalSharedChatbotCount,
        setTotalSharedChatbotCount,
        findUserRole,
        userRoleInTeam,
        setUserRoleInTeam
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamContextProvider;
